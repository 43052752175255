<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Scenario detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';

const item_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
      { label: 'Background', field: 'background', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title', input_type: 'text', validate: { required: true }, },
      { label: 'Career field', field: 'career_field', input_type: 'text', validate: { required: true } },
      { label: 'Position', field: 'position', input_type: 'text', validate: { required: true } },
    ]
  }
];

export default {
  data(){
    return{
      item_form_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Scenario list',
          to: { name: 'ai_scenario-list' },
        },
        {
          text: `${this.item ? this.item.title : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
  },
  methods:{
    // level
    async getItem(){
      this.item = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "ai_scenario-list" });
    },
  }
}
</script>
